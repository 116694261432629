<template>
    <div
        v-if="newProducts.length > 0"
        class="container"
    >
        <div class="heading">
            <h3>{{ $t('page.home.newProductsHeading') }}</h3>
        </div>
        <product-listing-slider :products="newProducts" />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import ProductListingSlider from '@/components/listing/ProductListingSlider';
    export default {
        name: 'HomeNewProducts',
        components: {
            ProductListingSlider,
        },
        computed: {
            ...mapState('home', ['newProducts']),
        },
    };
</script>

<style scoped lang="scss">
.products {
  .container {
    h3 {
      @include media-breakpoint-up(sm) {
        margin: 0;
      }
    }
  }
}
</style>
